import React from "react";






const Dashboard = React.lazy(() =>
  import("./components/layout/dashboard/Dashboard")
);
const HomePage = React.lazy(() => import("./components/layout/home/Home"));

const PricingPage = React.lazy(() =>
  import("./components/layout/pricing/Pricing")
);

const GetEarlyAccess = React.lazy(() =>
  import("./components/layout/early-access/GetEarlyAccess")
);
const ThankYou = React.lazy(() =>
  import("./components/layout/thank-you/ThankYou")
);

const TermsConditions = React.lazy(() =>
  import("./components/layout/terms-policy/TermsConditions")
);
const PrivacyPolicy = React.lazy(() =>
  import("./components/layout/terms-policy/PrivacyPolicy")
);

const RefundCancellationPolicy = React.lazy(() => import("./components/layout/refund-policy/RefundPolicy"))

const UserManual = React.lazy(() =>
  import("./components/layout/user-manual/UserManual")
);

const CategoryList = React.lazy(() =>
  import("./components/layout/user-manual/CategoryList")
);

const CategoryView = React.lazy(() =>
  import("./components/layout/user-manual/CategoryView")
);

const HomePageSecond = React.lazy(() =>
  import("./components/layout/home/home-second")
);

const ContactUs = React.lazy(() => import("./components/layout/contactus/Contactus"))
const ComingSoonPage = React.lazy(() => import("./components/layout/coming-soon/ComingSoon"))


const routes = [
  { path: "dashboard", component: Dashboard },
  { path: "/", component: HomePage },
  { path: "pricing", component: PricingPage },
  { path: "get-early-access", component: GetEarlyAccess },
  { path: "thank-you", component: ThankYou },
  { path: "terms-and-conditions", component: TermsConditions },
  { path: "refund-policy", component: RefundCancellationPolicy },
  { path: "privacy-policy", component: PrivacyPolicy },
  { path: "user-manual", component: UserManual },
  { path: "category-list", component: CategoryList },
  { path: "category-view", component: CategoryView },
  { path: "contact-us", component: ContactUs },
  { path: "coming-soon", component: ComingSoonPage },
  { path: "home-page", component: HomePageSecond },
];

export default routes;
