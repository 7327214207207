import MainContent from "./MainContent.jsx";

import logoBlack from "../../../assets/images/logo-black.png";
import kitaabVertical from "../../../assets/images/kitaabh-vertical.png";
import { Link, Navigate, useLocation, NavLink } from "react-router-dom";
import LoadingBar from "react-top-loading-bar";
import { useEffect, useRef, useState } from "react";
const MainLayout = () => {
  const handleEarlyAccess = () => {
    // localStorage.clear();
    Navigate("/get-early-access");
  };

  const ref = useRef(null);
  const location = useLocation();
  useEffect(() => {
    console.log(location);

    ref.current.continuousStart()
    setTimeout(() => {
      ref.current.complete()
    }, 100)
  }, [location])

  const [isVisible, setIsVisible] = useState(false);

  // Button will be visible after user scrolls down 300px
  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Smooth scroll effect
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);

    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  return (
    <>
      <LoadingBar color="#305ff0" ref={ref} shadow={true} height={3} />
      <div className="sb-nav-fixed overall-body">
        {/* <TheHeader /> */}
        <div className="scroll-to-top">
          {isVisible && (
            <button onClick={scrollToTop} className="btn btn-primary rounded-pill scroll-rounded-btn">
              <i className="fi fi-bs-angle-up l-h-1 v-align-middle"></i>
            </button>
          )}
        </div>
        <div
          id="layoutSidenav"
        // className={collapsed ? "sidebar-minimized" : "un-minimized"}
        >
          <div id="layoutSidenav_content" className={"create-vch-header"}>
            {location?.pathname !== "/coming-soon" ? <nav
              className={(location?.pathname !== "/user-manual" && location?.pathname !== "/category-list") ? "navbar navbar-toggleable-md navbar-expand-lg navbar-dark kitaab-navbar justify-content-center" : "navbar navbar-toggleable-md navbar-expand-lg navbar-dark kitaab-navbar kitaab-navbar-shadow justify-content-center"}
              id="kitaab-navbar"
            >
              <div className="container-fluid mobile-container-fluid">
                <a className="navbar-brand" href="/">
                  <img src={logoBlack} className="img-fluid" alt="Kitaabh Logo Black" />
                </a>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#mynavbar"
                >
                  <span className="navbar-toggler-icon">
                    <i className="fi fi-br-bars-staggered text-color-3 l-h-1 v-align-middle" />
                  </span>
                </button>
                <div className="collapse navbar-collapse" id="mynavbar">
                  <ul className="navbar-nav me-auto me-32 ms-32 ms-auto nav-links">
                    <li className="nav-item">
                      <NavLink to="/" exact activeClassName="active" className="nav-link center">
                        Home
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink to="/pricing" activeClassName="active" className="nav-link center">
                        Pricing
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink to="/coming-soon" className="nav-link center">
                        Partner with us
                      </NavLink>
                    </li>

                  </ul>
                  <form className="d-flex mobile-top-12 mobile-header-btns">
                    {/* <button
                      className="btn btn-outline-primary btn-padding me-16 btn-padding font-size-16 b-r-8"
                      type="button"
                    >
                      Book a Demo
                    </button> */}
                    <Link to="/get-early-access"

                      className="btn btn-primary btn-padding btn-padding font-size-16 b-r-8"
                    >
                      Get Early Access
                    </Link>
                  </form>
                </div>
              </div>
            </nav> : null}

            <main>
              <div className="">
                <MainContent />
              </div>
            </main>

            {location?.pathname !== "/coming-soon" && <footer className="bg-white text-light footer-shadow">
              <div className="container p-5 mobile-p-56 mobile-p-s-e-0">
                <div className="row">
                  <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="row pt-3 pb-3 mobile-top-0-imp mobile-bottom-0-imp">
                      <div className="col-12 mobile-bottom-8 mobile-text-start ">
                        <img
                          src={logoBlack}
                          className="img-fluid"
                          alt="Kitaabh Footer Logo Black"
                        ></img>
                        <p className="font-size-14 text-color-1 mb-0 f-600 mt-16">Great Businesses deserve Great Accountants</p>
                        <p className="font-size-14 text-color-1 mb-0 f-600">Great Accountants deserve Kitaabh</p>
                      </div>
                      {/* <div className="col-6 text-end mobile-font-icons">
                        <button className="btn btn-link text-color-1 font-size-16 text-dec-none">
                          <i className="fi fi-brands-facebook"></i>
                        </button>
                        <button className="btn btn-link text-color-1 font-size-16 text-dec-none">
                          <i className="fi fi-brands-twitter-alt"></i>
                        </button>
                        <button className="btn btn-link text-color-1 font-size-16 text-dec-none">
                          <i className="fi fi-brands-instagram"></i>
                        </button>
                        <button className="btn btn-link text-color-1 font-size-16 text-dec-none">
                          <i className="fi fi-brands-linkedin"></i>
                        </button>
                        <button className="btn btn-link text-color-1 font-size-16 text-dec-none">
                          <i className="fi fi-brands-youtube"></i>
                        </button>
                      </div> */}
                    </div>
                  </div>

                  <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 mobile-m-t-32">
                    <div className="row">
                      <div className="col-12">
                        <div className="row">
                          <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6 mobile-bottom-16">
                            <div className="">
                              <p className="font-size-18 f-600 mb-0 text-color-1">Main</p>
                            </div>
                            <div id="product" className="footer-list mt-1 collapse show">
                              <ul className="list-group list-group-flush">
                                <li className="list-group-item">
                                  <a href="/">Home</a>
                                </li>
                                <li className="list-group-item">
                                  <Link to="/coming-soon">About</Link>
                                </li>
                                <li className="list-group-item">
                                  <Link to="/coming-soon">Features</Link>
                                </li>
                                <li className="list-group-item">
                                  <Link to="/pricing">Pricing</Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6 mobile-bottom-16">
                            <p className="font-size-18 f-600 mb-0 text-color-1">Learn More</p>
                            <div className="footer-list mt-1">
                              <ul className="list-group list-group-flush">
                                <li className="list-group-item">
                                  <Link to="/contact-us">Contact Us</Link>
                                </li>
                                <li className="list-group-item">
                                  <Link to="/terms-and-conditions">Terms</Link>
                                </li>
                                <li className="list-group-item">
                                  <Link to="/privacy-policy">Privacy</Link>
                                </li>
                                <li className="list-group-item">
                                  <Link to="/refund-policy">Refund Policy</Link>
                                </li>

                              </ul>
                            </div>
                          </div>
                          <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6 mobile-bottom-16">
                            <p className="font-size-18 f-600 mb-0 text-color-1">Support</p>
                            <div className="footer-list  mt-1">
                              <ul className="list-group list-group-flush">
                                <li className="list-group-item">
                                  <Link to="/coming-soon">User Manual</Link>
                                </li>

                              </ul>
                            </div>
                          </div>

                        </div>
                      </div>
                      {/* <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12">
                        <div className="row">
                          <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <p className="font-size-18 f-600 mb-0 text-color-1">
                              Join Kitaabh Now
                            </p>
                            <p className="font-size-16 f-500 text-color-1 mb-0  mt-1">
                              Get early access and experience Kitaabh's powerful
                              accounting features
                            </p>
                            <div className="mt-16">
                              <div className="p-rel">
                                <input
                                  type="text"
                                  className="form-control footer-inp"
                                  placeholder="Enter your Email"
                                ></input>
                                <button className="btn btn-link text-primary paper-plane-float"><i className="fi fi-rr-paper-plane-top"></i></button>
                              </div>
                            </div>
                            
                          </div>
                        </div>
                      </div> */}
                    </div>


                  </div>
                </div>

                <div className="row align-items-center m-t-24">
                  <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="text-start py-1">
                      <p className="font-size-14 mb-0 text-color-2">2024 &copy; Kitaabh. All Rights Reserved. </p>
                    </div>
                  </div>

                </div>
              </div>
            </footer>}
          </div>
        </div>
      </div>
    </>
  );
};

export default MainLayout;
