import { memo } from 'react';
import {
    Outlet,
} from 'react-router-dom';

// routes config



const MainContent = () => {
    return (
        <main className="">
            <div>

                <Outlet />
            </div>
        </main>
    );
};

export default memo(MainContent);
